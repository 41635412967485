import { axios } from "./index";

export default {
  /**
   * Get a listing of inbox
   *
   * @param {Object} params Key-value pairs to use as query params
   */
  all(params) {
    return axios
      .get("email-notifications", {
        params,
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Get an email account
   *
   * @param {Integer} notification_id  Id of email account to load
   */
  show(notification_id) {
    return axios
      .get("email-notifications/" + notification_id)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Update email account with given ID
   *
   * @param {Object} data The data to use for updating the email account
   */
    add(data) {
      return axios
        .post("email-notifications", data)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response.data));
    },

  /**
   * Update email account with given ID
   *
   * @param {Integer} notification_id Id of the email account
   * @param {Object} data The data to use for updating the email account
   */
  update(notification_id, data) {
    return axios
      .put("email-notifications/" + notification_id, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Get an email account
   *
   * @param {Integer} notification_id  Id of email account to load
   */
  delete(notification_id) {
    return axios
      .delete(`email-notifications/${notification_id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },
};