// email account mutations
export const ENABLE_VIEW = "ENABLE_VIEW";
export const GET_USER = "GET_USER";
export const GET_EMAIL_ACCOUNTS = "GET_EMAIL_ACCOUNTS";
export const SHOW_EMAIL_ACCOUNTS = "SHOW_EMAIL_ACCOUNTS";
export const GET_SMTP_ACCOUNT_TYPES = "GET_SMTP_ACCOUNT_TYPES";
export const GET_IMAP_ACCOUNT_TYPES = "GET_IMAP_ACCOUNT_TYPES";
export const GET_CODE_URL = "GET_CODE_URL";
export const GENERATE_CODE_URL = "GENERATE_CODE_URL";
export const GET_EMAIL_CONTACTS = "GET_EMAIL_CONTACTS";
export const GET_INBOX_MESSAGES = "GET_INBOX_MESSAGES";
export const GET_COMMUNICATION_CHANNEL = "GET_COMMUNICATION_CHANNEL";
export const SHOW_COMMUNICATION_CHANNEL = "SHOW_COMMUNICATION_CHANNEL";
export const LOAD_EMAIL_CONTACTS = 'LOAD_EMAIL_CONTACTS'
export const GET_EMAIL_NOTIFICATION = "GET_EMAIL_NOTIFICATION";
export const SHOW_EMAIL_NOTIFICATION = "SHOW_EMAIL_NOTIFICATION";

export const GET_PAGES = "GET_PAGES";
export const GET_TOTAL_ROWS = "GET_TOTAL_ROWS";
export const EMPTY_INBOX = "EMPTY_INBOX";
export const GET_ALL_TAGS = "GET_ALL_TAGS";

export const GET_GROUP_EMAIL_CONTACTS = "GET_GROUP_EMAIL_CONTACTS";
export const GET_REPLY_LATER = "GET_REPLY_LATER";
export const GET_READ_LATER = "GET_READ_LATER";
export const GET_WRITE_LATER = "GET_WRITE_LATER";
export const GET_ALL_FOLDERS = "GET_ALL_FOLDERS";

export const ACTIVE_COMMUNICATION_CHANNEL = "ACTIVE_COMMUNICATION_CHANNEL"
export const GET_MAIN_FOLDERS = "GET_MAIN_FOLDERS"