/* eslint-disable no-unused-vars */
import CommunicationChannelService from '../services/communication_channel';
import { GET_COMMUNICATION_CHANNEL, SHOW_COMMUNICATION_CHANNEL, ACTIVE_COMMUNICATION_CHANNEL } from '../mutation-types';
import Toastify from 'toastify-js';

export const state = {
	communication_channels: [],
	show_communication_channel: {},
	active_communication_channel: null,
};

export const getters = {
	getAllChannels(state) {
		return state.communication_channels
	},
	getActiveCommunicationChannel(state) {
		return state.active_communication_channel
	},
	showChannel(state) {
		return state.show_communication_channel
	}
}

export const mutations = {
	[GET_COMMUNICATION_CHANNEL](state, data) {
		state.communication_channels = data;
	},

	[SHOW_COMMUNICATION_CHANNEL](state, id) {
		const single_channel_data = state.communication_channels.filter(item => item.id === id)[0]
		state.show_communication_channel = single_channel_data;
	},

	[ACTIVE_COMMUNICATION_CHANNEL](state, data) {
		state.active_communication_channel = data;
	},
};

export const actions = {
	allChannel({ commit, dispatch }, query) {

		return window.cxSocketApi.talk("read-communication-channels", (response) => {
			commit(GET_COMMUNICATION_CHANNEL, response.data);
			const account_id = localStorage.getItem('email_account_id')

			if (account_id !== null) {
				if (account_id !== undefined) {
					dispatch('showChannel', +account_id)
				}
			}
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		})
	},
	testImapConnection({ commit, dispatch }, query) {
		return new Promise((resolve,reject)=>{
			window.cxSocketApi.talk("test-imap-connection", (response) => {
				resolve("success")
			}, (error) => {
				Toastify({
					text: `${error.data} !`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "#d0021b",
					}
				}).showToast();
				resolve(error);
			},query)

		})
	},
	addChannel({ commit, actions }, payload) {
		return window.cxSocketApi.talk("create-communication-channel", (response) => {
			if (response) {
				Toastify({
					text: `communication channel created successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	showChannel({ commit }, id) {

		commit(SHOW_COMMUNICATION_CHANNEL, id);
	},
	activeCommunicationChannel({ commit }, id) {
		localStorage.setItem('email_account_id', id)
		commit(ACTIVE_COMMUNICATION_CHANNEL, id);
	},


	updateChannel({ commit, actions }, payload) {
		return window.cxSocketApi.talk("update-communication-channel", (response) => {
			if (response) {
				Toastify({
					text: `communication channel updated successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	deleteChannel({ commit, actions }, id) {

		return window.cxSocketApi.talk("delete-communication-channel", (response) => {
			Toastify({
				text: `communication channel delete successfully !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 4000,
				style: {
					background: "linear-gradient(to right, #00b09b, #96c93d)",
				}
			}).showToast();

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, id)
	},
};

