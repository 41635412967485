import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'email' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'email' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            // beforeResolve(routeTo, routeFrom, next) {
            //     // If the user is already logged in
            //     if (store.getters['auth/loggedIn']) {
            //         // Redirect to the home page instead
            //         next({ name: 'email' })
            //     } else {
            //         // Continue to the login page
            //         next()
            //     }
            // },
        },
    },
    {
        path: '/verify-email',
        name: 'verify',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/verify-email')
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard')
    },

    {
        path: '/emails',
        name: 'email',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/channels/emails')
    },

    // {
    //     path: '/email-channel',
    //     name: 'email',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/channels/emailchannel')
    // },

    {
        path: '/chat-channel',
        name: 'chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/channels/chatchannel')
    },

    {
        path: '/sms-channel',
        name: 'sms',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/channels/smschannel')
    },
    {
        path: '/email-details',
        name: 'email-details',
        meta: {
            authRequired: true,
        },
        component: () => import('../components/email/reademail')
    },
    {
        path: '/email-account-settings',
        name: 'email account settings',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/email_account/index')
    },
    {
        path: '/code',
        name: 'code',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/code/code')
    },
   
]
