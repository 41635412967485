<template>
  <div>
    <div class="folder_view_card" :style="indent" @click.stop="loadFolder(subFolder)" v-for="(subFolder, index) in folderData" :key="index">
      <div class="icon-view" :class="dropdownIds && dropdownIds.filter(x => x === subFolder.id)[0] === subFolder.id ? 'is_icon_dropdown' : ''"><i class="mdi mdi-chevron-right"></i>
      </div>

      <div class="folder__image__view">
        <div class="main_folder_view" @contextmenu.prevent="onRightFolderClick(subFolder.id)">
          <img :src="folderImg" alt="folder" />
          <span> {{ subFolder.name }}</span>
        </div>
        <div class="dropdown__menu" v-if="folder_menu_dropdown_id === subFolder.id" v-click-outside="onClickOutsideFolderMenu">
          <div class="menu_item">
            <span>Add</span>
          </div>
          <div class="menu_item">
            <span>Rename</span>
          </div>
          <div class="menu_item">
            <span>Delete</span>
          </div>
        </div>
        <SubFolder v-if="subFolder.sub.length > 0 && dropdownIds.filter(x => x === subFolder.id)[0] === subFolder.id" :dataTree="subFolder.sub" :folderImg="folderImg"
                     :indent="indent" :depth="depth + 1"
                     :loadFolder="loadFolder"
                     :dropdown_menu_icons="dropdownIds"
                    :folder_menu_dropdown_id="folder_menu_dropdown_id" :onRightFolderClick="onRightFolderClick"
                    :onClickOutsideFolderMenu="onClickOutsideFolderMenu" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['folderImg', 'depth', 'indent', 'dataTree', 'loadFolder',
    'folder_menu_dropdown_id','dropdown_menu_icons', 'onRightFolderClick', 'onClickOutsideFolderMenu'
  ],
  data() {
    return {
      folderData: this.dataTree,
      dropdownIds: this.dropdown_menu_icons,
    }
  },
  watch: {
    dataTree: {
      handler(data) {
         this.folderData = data
      },
      // force eager callback execution
      deep: true
    },
    dropdown_menu_icons: {
      handler(data) {
         this.dropdownIds = data
      },
      // force eager callback execution
      deep: true
    }
  },
  mounted(){
    
  }
}
</script>

<style>

</style>