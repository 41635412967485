import { axios } from "./index";

export default {
  /**
   * Get a listing of inbox
   *
   * @param {Object} params Key-value pairs to use as query params
   */
  all(params) {
    return axios
      .get("email-accounts", {
        params,
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Get an email account
   *
   * @param {Integer} email_account_id  Id of email account to load
   */
  show(email_account_id) {
    return axios
      .get("email_accounts/" + email_account_id)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Update email account with given ID
   *
   * @param {Object} data The data to use for updating the email account
   */
    add(data) {
      return axios
        .post("email-accounts", data)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response.data));
    },

  /**
   * Update email account with given ID
   *
   * @param {Integer} email_account_id Id of the email account
   * @param {Object} data The data to use for updating the email account
   */
  update(email_account_id, data) {
    return axios
      .put("email-accounts/" + email_account_id, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Get an email account
   *
   * @param {Integer} email_account_id  Id of email account to load
   */
  delete(email_account_id) {
    return axios
      .delete(`email-accounts/${email_account_id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Get a listing of smtp account type
   *
   * @param {Object} params Key-value pairs to use as query params
   */
  smtpAccountType(params) {
    return axios
      .get("smtp-account-types", {
        params,
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },

  /**
   * Get a listing of smtp account type
   *
   * @param {Object} params Key-value pairs to use as query params
   */
  imapAccountType(params) {
    return axios
      .get("imap-account-types", {
        params,
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },
  /**
   * Get a listing of inbox
   *
   * @param {Object} params Key-value pairs to use as query params
   */
  getCodeUrl(params) {
      return axios
        .get("generate-code", {
          params,
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response.data));
    },

  /**
   * Get a listing of token
   *
   * @param {Object} params Key-value pairs to use as query params
   */
  generateToken(params) {
    return axios
      .get("token", {
        params,
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
  },
    /**
   * Get a listing of inbox
   *
   * @param {Object} params Key-value pairs to use as query params
   */
    getEmailContact(params) {
      return axios
        .get("email-contacts", {
          params,
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response.data));
    },
        /**
   * Get a listing of inbox
   *
   * @param {Object} params Key-value pairs to use as query params
   */
        searchEmailContact(params) {
          return axios
            .get("search-email-contacts", {
              params,
            })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error.response.data));
        },
};


