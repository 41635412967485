import { GET_USER } from "../mutation-types";
import Toastify from 'toastify-js';
import router from '../../router/index';

export const state = {
    user:{},
};

export const getters = {
    getUser(state)
    {
        return state.user
    },
}

export const mutations = {
	[GET_USER] (state, payload) {
		state.user = payload;
	},
	
};

export const actions = {
      // eslint-disable-next-line no-unused-vars
      getUserData({ commit, actions }) {
        return window.cxSocketApi.talk("user", (response) => {
           commit(GET_USER, response.data);
           return response;
           }, (error) => {
             
             Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           });
	},

    // eslint-disable-next-line no-unused-vars
    login({ commit, actions }, payload) {
        return window.cxSocketApi.talk("login", (response) => {
            if(response) {
                localStorage.setItem('connectxone_token', response.data.token)
                Toastify({
                    text: `Login successfully, welcom back ${response.data.user.name} !`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();    

                    router.push('/');
                    
            }
           return response;
           }, (error) => {
             
             Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, payload);
	},
    // eslint-disable-next-line no-unused-vars
    register({ commit, actions }, payload) {
        return window.cxSocketApi.talk("register", (response) => {
            if(response) {
                Toastify({
                    text: `Account created successfully, login to use connectxone !`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
                    router.push('/login');
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, payload);
	},
 // eslint-disable-next-line no-unused-vars
    forgetPasword({ commit, actions }, payload) {
        return window.cxSocketApi.talk("reset", (response) => {
            if(response) {
                Toastify({
                    text: `New password has been sent to your email`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
                    router.push('/login');
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, payload);
	},

}