/* eslint-disable no-unused-vars */
import EmailAccountService from '../services/email_account';
import {
	GET_EMAIL_ACCOUNTS, SHOW_EMAIL_ACCOUNTS, GET_SMTP_ACCOUNT_TYPES, GET_ALL_FOLDERS,
	GET_IMAP_ACCOUNT_TYPES, GET_CODE_URL, GET_ALL_TAGS, GENERATE_CODE_URL, GET_EMAIL_CONTACTS, LOAD_EMAIL_CONTACTS, GET_GROUP_EMAIL_CONTACTS, GET_MAIN_FOLDERS
} from '../mutation-types';
import Toastify from 'toastify-js';

export const state = {
	email_accounts: [],
	smtp_account_type: [],
	imap_account_type: [],
	show_email_account: [],
	group_email_contacts: [],
	code_url: "",
	token: null,
	email_contacts: [],
	tags: [],
	folders: [],
};

export const getters = {
	getAll(state) {
		return state.email_accounts
	},
	getSmtpAccountType(state) {
		return state.smtp_account_type
	},
	getImapAccountType(state) {
		return state.imap_account_type
	},

	getEmailAccount(state) {
		return state.show_email_account[0]
	},
	getCodeUrl(state) {
		return state.code_url
	},
	getToken(state) {
		return state.token
	},
	getEmailContacts(state) {
		return state.email_contacts
	},
	getAllFolders(state) {
		return state.folders
	},
	getGroupEmailContacts(state) {
		return state.group_email_contacts
	},
	getAllTags(state) {
		return state.tags
	},
}

export const mutations = {
	[GET_EMAIL_ACCOUNTS](state, payload) {
		state.email_accounts = payload;
	},
	[GET_ALL_TAGS](state, payload) {
		state.tags = payload;
	},
	[GET_ALL_FOLDERS](state, payload) {

		// find folder
		// const findDeepNestedObject = (array, key, value) => {
		// 	return array.find(obj => {
		// 		if (obj[key] === value) return obj;
		// 		if (obj.children) return findDeepNestedObject(obj.children, key, value);
		// 	});
		// }

		function findDeepNestedObject(collection, key, value) {
			for (const o of collection) {
				for (const [k, v] of Object.entries(o)) {
					if (k === key && v === value) {
						return o
					}
					if (Array.isArray(v)) {
						const _o = findDeepNestedObject(v, key, value)
						if (_o) {
							return _o
						}
					}
				}
			}
		}



		payload.item.forEach(item => {

			
			const clickable_data = findDeepNestedObject(state.folders,'id', payload.clicked_id)
			console.log('clicked_data: ', clickable_data)

			const new_data = findDeepNestedObject(state.folders, 'id', item.id)
			console.log('new data: ', new_data)
			if(new_data === undefined) {
				
				item.sub = []
				clickable_data.sub.push(item)
				
			} else {
				const existing_folder = clickable_data.sub.filter(elm => elm.id === item.id)
				console.log('existing_datas: ', existing_folder)
			

				if(existing_folder.length === 0) {
					new_data.sub.push(item)
					
				}
			}
		
		})
		console.log("folders: ", state.folders)
	},
	[GET_MAIN_FOLDERS](state, payload) {
		const existing_folder = state.folders.filter(item => item.id === payload.id)
		if(existing_folder.length === 0) {
			state.folders.push(payload);
		}
		
	},
	[GET_GROUP_EMAIL_CONTACTS](state, payload) {
		state.group_email_contacts = payload;
	},
	[GET_SMTP_ACCOUNT_TYPES](state, payload) {
		state.smtp_account_type = payload;
	},
	[GET_IMAP_ACCOUNT_TYPES](state, payload) {
		state.imap_account_type = payload;
	},
	[SHOW_EMAIL_ACCOUNTS](state, payload) {

		state.show_email_account = state.email_accounts.data.filter((item) => {
			return item.id === payload;
		});
	},
	[GET_CODE_URL](state, payload) {
		state.code_url = payload;
	},
	[GENERATE_CODE_URL](state, payload) {

	},
	[GET_EMAIL_CONTACTS](state, payload) {
		state.email_contacts = payload.data;

	},
	[LOAD_EMAIL_CONTACTS](state, payload) {
		state.email_contacts.push(payload);
	}
};

export const actions = {
	all({ commit }, query) {
		return EmailAccountService.all(query)
			.then((response) => {
				commit(GET_EMAIL_ACCOUNTS, response);
				return Promise.resolve(response);
			})
			.catch((error) => Promise.reject(error));
	},

	add({ commit, actions }, payload) {
		return EmailAccountService.add(payload)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	show({ commit, actions }, id) {
		return EmailAccountService.show(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	update({ commit, actions }, email_account) {
		return EmailAccountService.update(email_account.id, email_account)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	delete({ commit, actions }, id) {
		return EmailAccountService.delete(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},
	smtpAccountType({ commit }, query) {
		return window.cxSocketApi.talk("read-smtp-types", (response) => {
			commit(GET_SMTP_ACCOUNT_TYPES, response.data);
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		})
	},
	imapAccountType({ commit }, query) {
		return window.cxSocketApi.talk("read-imap-types", (response) => {
			commit(GET_IMAP_ACCOUNT_TYPES, response.data);
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		})
	},
	codeUrl({ commit }, query) {
		return window.cxSocketApi.talk("gmail-auth-url", (response) => {
			window.open(response.data, "_blank").focus();
			commit(GET_CODE_URL, response.data);
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		})
	},
	generateToken({ commit }, payload) {
		return window.cxSocketApi.talk("create-communication-channel", (response) => {
			if (response) {
				Toastify({
					text: `communication channel created successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},
	allEmailContact({ commit }, payload) {
		return window.cxSocketApi.talk("read-email-contacts", (response) => {
			commit(GET_EMAIL_CONTACTS, response);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	allFolders({ commit }, payload) {
		return window.cxSocketApi.talk("read-sub-folders", (response) => {
			commit(GET_ALL_FOLDERS, {item: response.data, clicked_id: payload.clicked_id});
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload.data);
	},

	getMainFolder({ commit, state }, payload) {
		return commit(GET_MAIN_FOLDERS, payload);
	},

	allTags({ commit }, payload) {
		return window.cxSocketApi.talk("read-tags", (response) => {
			commit(GET_ALL_TAGS, response.data);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	allGroupContact({ commit }, payload) {
		return window.cxSocketApi.talk("read-contact-groups", (response) => {
			commit(GET_GROUP_EMAIL_CONTACTS, response.data.data);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},


	// allEmailContact({ commit }, query) {
	// 	return EmailAccountService.getEmailContact(query)
	// 		.then((response) => {
	// 			commit(GET_EMAIL_CONTACTS, response, query);
	// 			return Promise.resolve(response);
	// 		}).catch((error) => Promise.reject(error));
	// },

	searchEmailContact({ commit }, query) {
		return EmailAccountService.searchEmailContact(query)
			.then((response) => {
				commit(GET_EMAIL_CONTACTS, response);
				return Promise.resolve(response);
			})
			.catch((error) => Promise.reject(error));
	},

	// loadMoreEmailContact({ commit, state }, query) {
	// 	return EmailAccountService.getEmailContact(query)
	// 		.then((response) => {
	// 			response.data.forEach((item) => {
	// 				if (!state.email_contacts.some(data => data.id === item.id)) {
	// 					commit(LOAD_EMAIL_CONTACTS, item);
	// 				}
	// 			});
	// 			return Promise.resolve(response);
	// 		})
	// 		.catch((error) => Promise.reject(error));

	// }

	loadMoreEmailContact({ commit, state }, payload) {
		return window.cxSocketApi.talk("read-email-contacts", (response) => {
			if (response.data.length > 0)
				response.data.forEach((item) => {
					commit(LOAD_EMAIL_CONTACTS, item);
					// if (!state.email_contacts.some(data => data.id === item.id)) {
					// 	commit(LOAD_EMAIL_CONTACTS, item);
					// }
				});
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},
	addContact({ commit }, payload) {
		return window.cxSocketApi.talk("create-unified-contact", (response) => {
			if (response) {
				Toastify({
					text: `contact updated successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	updateContact({ commit }, payload) {
		return window.cxSocketApi.talk("update-unified-contact", (response) => {
			if (response) {
				Toastify({
					text: `contact updated successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	deleteContact({ commit }, id) {
		return window.cxSocketApi.talk("delete-email-contact", (response) => {
			if (response) {
				Toastify({
					text: `contact deleted successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, id);
	},
	addTags({ commit }, tags) {
		return window.cxSocketApi.talk("create-contact-tag", (response) => {
			if (response) {
				Toastify({
					text: `tags created successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, tags);
	},
	addContactTags({ commit }, tags) {
		return window.cxSocketApi.talk("add-tags-to-contact", (response) => {
			if (response) {
				Toastify({
					text: `tags created successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, tags);
	},
	removeTags({ commit }, id) {
		return window.cxSocketApi.talk("remove-tag-from-contact", (response) => {
			if (response) {
				Toastify({
					text: `tags deleted successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, id);
	},
	addFolder({ commit }, folders) {
		return window.cxSocketApi.talk("create-folder", (response) => {
			if (response) {
				Toastify({
					text: `folder created successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, folders);
	},
	deleteFolder({ commit }, id) {
		return window.cxSocketApi.talk("delete-folder", (response) => {
			if (response) {
				Toastify({
					text: `folder deleted successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, id);
	},
	editFolder({ commit }, folders) {
		return window.cxSocketApi.talk("update-folder", (response) => {
			if (response) {
				Toastify({
					text: `folder updated successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, folders);
	},

};

