export default {
    production: {
      echoURL: 'https://restapi.connectxone.net/api/v1/',
      baseURL: 'https://restapi.connectxone.net/api/v1/',
      mainURL:'https://restapi.connectxone.net',
      socketURL:'wss://api.connectxone.net:6001'
    },
  
    development: {
      echoURL: 'https://restapi.connectxone.net/api/v1/',
      baseURL: 'https://restapi.connectxone.net/api/v1/',
      mainURL:'https://restapi.connectxone.net',
      socketURL:'wss://api.connectxone.net:6001'
    },

  //   development: {
  //     echoURL: 'http://127.0.0.1:8000/api/v1/',
  //     baseURL: 'http://127.0.0.1:8000/api/v1/',
  //     mainURL:'http://127.0.0.1:8000',
  //     socketURL:'ws://127.0.0.1:6001'
  // },
  }
  