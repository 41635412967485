/* eslint-disable no-unused-vars */
import ComposeMessageService from '../services/composeemail';
import Toastify from 'toastify-js';

export const actions = {
	add({ commit, actions }, payload) {
		return window.cxSocketApi.talk("compose-email", (response) => {
			if (response) {
				Toastify({
					text: `email send successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	},

	// add({ commit, actions }, payload) {
	// 	return ComposeMessageService.add(payload)
	// 		.then((response) => Promise.resolve(response))
	// 		.catch((error) => Promise.reject(error));
	// },
	replyEmailMessage({ commit, actions }, payload) {
		return window.cxSocketApi.talk("reply-email", (response) => {
			if (response) {
				Toastify({
					text: `email send successfully!`,
					gravity: "top", // `top` or `bottom`
					position: "center", // `left`, `center` or `right`
					duration: 3000,
					style: {
						background: "linear-gradient(to right, #00b09b, #96c93d)",
					}
				}).showToast();

			}

			return response;
		}, (error) => {
			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, payload);
	}
}
