import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const emailAccountComputed = {
    ...mapState('eaccount', {
        email_accounts:(state) => state.email_accounts,
        smtp_account_type:(state) => state.smtp_account_type,
        imap_account_type:(state) => state.imap_account_type,
        show_email_account:(state) => state.show_email_account,
        show_code_url:(state) => state.show_code_url,
        token:(state) => state.token,
        email_contacts:(state) => state.email_contacts,
        group_email_contacts:(state) => state.group_email_contacts,
        tags:(state) => state.tags,
        folders:(state) => state.folders,
    }),
    ...mapGetters('eaccount', ['getAll','getAllTags','getAllFolders', 'getSmtpAccountType','getGroupEmailContacts', 'getImapAccountType', 'getEmailAccount', 'getCodeUrl', 'getToken', 'getEmailContacts'])
}

// export email account actions
export const emailAccountMethods = mapActions('eaccount', ['all', 'getMainFolder', 'allTags','allFolders', 'allGroupContact', 'add', 'show', 'update', 'delete', 'smtpAccountType', 'imapAccountType', 'codeUrl', 'generateToken', 'allEmailContact', 'searchEmailContact', 'loadMoreEmailContact'
,'updateContact','deleteContact','addTags','addContact', 'addContactTags','removeTags','addFolder','deleteFolder','editFolder',
])
export const emailAccountMutations = mapMutations('eaccount', ['SHOW_EMAIL_ACCOUNTS', 'GET_EMAIL_CONTACTS']);