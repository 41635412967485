/* eslint-disable no-unused-vars */
import EmailNotificationService from '../services/email_notification';
import { GET_EMAIL_NOTIFICATION, SHOW_EMAIL_NOTIFICATION} from '../mutation-types';


export const state = {
  email_notifications:[],
	show_email_notification:{},
};

export const getters = {
    getAllChannels(state)
    {
        return state.email_notifications
    },
}

export const mutations = {
	[GET_EMAIL_NOTIFICATION] (state, payload) {
		state.email_notifications = payload;
	},
	
	[SHOW_EMAIL_NOTIFICATION](state, payload){
		state.show_email_notification = payload;
	},
};

export const actions = {
	allEmailNotification({ commit }, query) {
		return EmailNotificationService.all(query)
			.then((response) => {
				commit(GET_EMAIL_NOTIFICATION, response);
				return Promise.resolve(response);
			})
			.catch((error) => Promise.reject(error));
	},

	addEmailNotification({ commit, actions }, payload) {
		return EmailNotificationService.add(payload)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	showEmailNotification({ commit }, id) {
		return EmailNotificationService.show(id)
			.then((response) => 
			{
				commit(SHOW_EMAIL_NOTIFICATION, response)
				return Promise.resolve(response)
			})
			.catch((error) => Promise.reject(error));
	},

	updateEmailNotification({ commit, actions }, email_notifcation) {
		return EmailNotificationService.update(email_notifcation.id, email_notifcation)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},	

	deleteEmailNotification({ commit, actions }, id) {
		return EmailNotificationService.delete(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},
};

