<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
// import Echo from 'laravel-echo'
import { communicationChannelMethods } from "./state/helpers/communicationchannel";
import { emailAccountMethods } from "./state/helpers/eaccount";
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  methods:{
        ...emailAccountMethods,
        ...communicationChannelMethods,   
 listenForEmails(){
                // const echo = new Echo({
                //     broadcaster: 'socket.io',
                //     host: '127.0.0.1:6003',
                //     forceTLS: false,
                //     transports: ['websocket'],
                // });
                // console.log(echo)
                // echo.channel("new-emails").listen("NewEmailEvent",()=>{
                //     // alert("new Message")
                //    const email_account_id = localStorage.getItem("email_account_id");
                //     this.allChannel();
                //     this.allEmailContact({ email_account_id: email_account_id})
                // });
            },
  },
  mounted(){    
    this.listenForEmails();
  }
};
</script>