import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const communicationChannelComputed = {
    ...mapState('communicationchannel', {
        communication_channels:(state) => state.communication_channels,
        show_communication_channel:(state) => state.show_communication_channel,
        active_communication_channel:(state) => state.active_communication_channel,
    }),
    ...mapGetters('communicationchannel', ['getAllChannels', 'getActiveCommunicationChannel'])
}

// export email account actions
export const communicationChannelMethods = mapActions('communicationchannel', ['allChannel', 'addChannel', 'showChannel', 'updateChannel', 'deleteChannel', 'activeCommunicationChannel','testImapConnection'])
export const communicationChannelMutations = mapMutations('communicationchannel', ['SHOW_COMMUNICATION_CHANNEL', 'ACTIVE_COMMUNICATION_CHANNEL']);