import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import VueFileAgent from 'vue-file-agent';
import LoadMore from 'vue-scroll-loadmore'
// eslint-disable-next-line no-unused-vars
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import InfiniteLoading from 'vue-infinite-loading';
import vSelect from 'vue-select'
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";
import "toastify-js/src/toastify.css"
import 'vue-select/dist/vue-select.css';
import SubFolder from "./components/subfolder.vue";
import { initFirebaseBackend } from './helpers/firebase/authUtils';
import Config from './config/app';
import { configureFakeBackend } from './helpers/fakebackend/fake-backend';
import * as truncate from './utils/truncate'
import {ConnectxoneInitializer} from "./helpers/socket/cx_api";

const cxOne = new ConnectxoneInitializer()
cxOne.cxOneConnect(Config[process.env.NODE_ENV].socketURL, null, "connectxone_token")


const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

// Vue.filter('truncate', truncate);
Object.keys(truncate).forEach(key => {
  Vue.filter(key, truncate[key])
})

Vue.config.productionTip = false
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(VModal)
Vue.use(LoadMore)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueFileAgent);
Vue.use(VueMask)
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect)
Vue.component('v-select', vSelect)
Vue.component('SubFolder', SubFolder);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
