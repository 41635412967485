/* eslint-disable no-unused-vars */
import EmailMessageService from '../services/email_inbox_messages';
import Toastify from 'toastify-js';
import { GET_INBOX_MESSAGES, GET_PAGES, GET_TOTAL_ROWS, EMPTY_INBOX, GET_REPLY_LATER,GET_WRITE_LATER, GET_READ_LATER,  } from '../mutation-types';

export const state = {
	email_inboxs: [],
	page: 1,
	total_rows: 1,
	reply_later_emails: [],
	read_later_emails: [],
	write_later_emails: [],
};

export const getters = {
	getAllInbox(state) {
		return state.email_inboxs
	},
	getAllReplyLaterEmails(state) {
		return state.reply_later_emails
	},
	getAllReadLaterEmails(state) {
		return state.read_later_emails
	},
	getAllWriteLaterEmails(state) {
		return state.write_later_emails
	},

	getPage(state) {
		return state.page
	},

	getTotalRows(state) {
		return state.total_rows
	},

}

export const mutations = {
	[GET_INBOX_MESSAGES](state, payload) {
		state.email_inboxs = payload;
	},

	[GET_PAGES](state, payload) {
		state.page = payload;
	},

	[GET_TOTAL_ROWS](state, payload) {
		state.total_rows = payload;
	},

	[EMPTY_INBOX](state, payload) {
		state.email_inboxs = payload;
		state.read_later_emails = payload;
		state.write_later_emails = payload;
		state.reply_later_emails = payload;
	},
	[GET_REPLY_LATER](state, payload) {
		state.reply_later_emails = payload;
	},
	[GET_READ_LATER](state, payload) {
		state.read_later_emails = payload;
	},
	[GET_WRITE_LATER](state, payload) {
		state.write_later_emails = payload;
	}
};



export const actions = {
	allInbox({ commit }, query) {
		return window.cxSocketApi.talk("read-emails", (response) => {
			commit(GET_INBOX_MESSAGES, response.data.data);
			commit(GET_PAGES, response.data.page);
			commit(GET_TOTAL_ROWS, response.data.total);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, query);
	},
  folderEmails({ commit }, query) {
		return window.cxSocketApi.talk("read-folder-emails", (response) => {
			commit(GET_INBOX_MESSAGES, response.data.data);
			commit(GET_PAGES, response.data.page);
			commit(GET_TOTAL_ROWS, response.data.total);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, query);
	},
  allGroupInbox({ commit }, query) {
		return window.cxSocketApi.talk("read-group-messages", (response) => {
			commit(GET_INBOX_MESSAGES, response.data.data);
			commit(GET_PAGES, response.data.page);
			commit(GET_TOTAL_ROWS, response.data.total);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, query);
	},
	deleteEmails({ commit }, id) {
		return window.cxSocketApi.talk("delete-email", (response) => {
            if(response) {
                Toastify({
                    text: `email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, id);
	},
	updateEmail({ commit }, emails) {
		return window.cxSocketApi.talk("update-email", (response) => {
            // if(response) {
            //     Toastify({
            //         text: `email updated successfully!`,
            //         gravity: "top", // `top` or `bottom`
            //         position: "center", // `left`, `center` or `right`
            //         duration: 3000,
            //         style: {
            //             background: "linear-gradient(to right, #00b09b, #96c93d)",
            //           }
            //         }).showToast();   
                    
            // }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, emails);
	},
	allReadLaterEmails({ commit }, query) {
		return window.cxSocketApi.talk("read-emails-to-read", (response) => {
			commit(GET_READ_LATER, response.data);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, query);
	},
	addReadLater({ commit }, data) {
		return window.cxSocketApi.talk("add-to-read-later", (response) => {
            if(response) {
                Toastify({
                    text: `email added to read later!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, data);
	},
	deleteReadLater({ commit }, id) {
		return window.cxSocketApi.talk("remove-from-read-later", (response) => {
            if(response) {
                Toastify({
                    text: `read later email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, id);
	},
	updateReadLater({ commit }, readlater) {
		return window.cxSocketApi.talk("delete-read-later", (response) => {
            if(response) {
                Toastify({
                    text: `read later email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, readlater);
	},

	allReplyLaterEmails({ commit }, query) {
		return window.cxSocketApi.talk("read-emails-to-reply", (response) => {
			commit(GET_REPLY_LATER, response.data);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, query);
	},
	deleteReplyLater({ commit }, id) {
		return window.cxSocketApi.talk("remove-from-reply-later", (response) => {
            if(response) {
                Toastify({
                    text: `reply later email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, id);
	},
	createReplyLater({ commit }, data) {
		return window.cxSocketApi.talk("add-to-reply-later", (response) => {
            if(response) {
                Toastify({
                    text: `email added to reply later successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, data);
	},

	updateReplyLater({ commit }, readlater) {
		return window.cxSocketApi.talk("delete-reply-later", (response) => {
            if(response) {
                Toastify({
                    text: `reply later email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
            } 
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, readlater);
	},

	allWriteLaterEmails({ commit }, query) {
		return window.cxSocketApi.talk("read-emails", (response) => {
			commit(GET_WRITE_LATER, response.data.data);
			return response;
		}, (error) => {

			Toastify({
				text: `${error.data} !`,
				gravity: "top", // `top` or `bottom`
				position: "center", // `left`, `center` or `right`
				duration: 3000,
				style: {
					background: "#d0021b",
				}
			}).showToast();
			return error;
		}, query);
	},
	deleteWriteLater({ commit }, id) {
		return window.cxSocketApi.talk("delete-write-later", (response) => {
            if(response) {
                Toastify({
                    text: `reply later email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, id);
	},
	updateWriteLater({ commit }, readlater) {
		return window.cxSocketApi.talk("delete-write-later", (response) => {
            if(response) {
                Toastify({
                    text: `write later email deleted successfully!`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
            } 
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, readlater);
	},
  moveEmailMessageToFolder({ commit }, data) {
		return window.cxSocketApi.talk("add-email-to-folder", (response) => {
            if(response) {
                Toastify({
                    text: `email move to folder successfully`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();   
                    
            }
            
            return response;
           }, (error) => {
            Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
             return error;
           }, data);
	},

	emptyInbox({ commit, actions }, id) {
		return commit(EMPTY_INBOX, []);
	},
}